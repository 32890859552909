import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

//loading
import { headerBtn } from './module/common/headerBtn';

//anchor link
import { anchorLink } from './module/common/anchorLink';

const setScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	// カスタムプロパティの値を更新する
	document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
};

setScrollbarWidth();
const loadingInner = document.querySelector('.loading__inner');
gsap.to(
	loadingInner,
	{
		opacity: 1,
		scale: 1,
		duration: 1,
		delay: .2,
		ease: "expo.out",
	},

);


const heroLogo = document.querySelector('.hero__logo');
const heroLogoInner = document.querySelector('.hero__logo__inner');
const heroText = document.querySelector('.hero__text .js--anim');
const heroBg = document.querySelector('.hero__bg img');
const heroNews = document.querySelector('.hero__news');
const loading = document.querySelector('.loading');
const loadingMain = document.querySelector('main');
const duration = 1.2;
const ease = 'none';

const loadAnim = function () {
	const headerBtn = document.querySelector('.header_btn');
	const tl = gsap.timeline({

	});
	/*tl.fromTo(
		document.querySelector('.hero'),
		{
			opacity: 1,
			y: 0,
		},
		{
			opacity: 0,
			y: '-=80',
			ease: "none",
		},

	);*/
	tl.to(
		loadingInner,
		{
			opacity: 0,
			scale: 1.4,
			duration: .5,
			delay: 1.2,
			ease: "expo.out",
		},

	);

	tl.to(
		loading,
		{
			'--clip5': 100,
			duration: .6,
			ease: "expo.out",
		}, "-=.3"

	);

	/*tl.fromTo(
		item,
		{
			opacity: 1,
			y: 0,
		},
		{
			opacity: 0,
			y: '-=80',
			ease: "none",
		},

	);
	tl.fromTo(
		logo,
		{
			opacity: 1,
		},
		{
			opacity: 0,
			ease: "none",
		}, '<',

	);*/
	/*tl.to(
		mov,
		{
			opacity: 0,
			ease: "none",
		}, '<',

	);
	tl.to(
		about,
		{
			opacity: 1,
			ease: "none",
		}, '<',

	);*/
	/*tl.fromTo(
		document.querySelector('.hero'),
		{
			opacity: 1,
			y: 0,
		},
		{
			opacity: 0,
			y: '-=80',
			ease: "none",
		},

	);*/
	tl.to(
		heroBg,
		{
			rotate: 0,
			scale: 1,
			opacity: 1,
			duration: 1,
			ease: "power3.out",
		},
		"-=.35"
	);
	tl.to(
		heroLogo,
		{
			'--clip3': 0,
			duration: .44,
			ease: "expo.out",
		},
		"-=.4"
	);
	tl.to(
		heroLogoInner,
		{
			'--wit': 0,
			duration: .44,
			ease: "expo.out",
		},
		"-=.3"

	);

	tl.to(
		heroText,
		{
			'--clip': '0',
			duration: .6,
			ease: "expo.out",
		}, "-=.3"

	);

	tl.to(
		heroText.children,
		{
			backgroundSize: '100% 100%',
			duration: .6,
			ease: "expo.out",
		}, "-=.4"

	);
	tl.to(
		heroNews,
		{
			translateY: 0,
			opacity: 1,
			duration: .8,
			ease: "expo.out",
			delay: .2,
		}, '-=.3',

	);
	tl.to(
		headerBtn,
		{
			scale: 1,
			opacity: 1,
			duration: .8,
			ease: "expo.out",
		}, '<',

	);
}
const heroScroll = function () {
	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: ".hero",
			scrub: true,
			start: "top top",
			end: "bottom top",
		},
	});

	tl2.to(
		document.querySelector('.hero__inner'),
		{
			yPercent: 130,
			ease:'none',
		},

	);
	tl2.to(
		document.querySelector('.hero__bg'),
		{
			marginTop: 360,
			opacity:.6,
			scale:.9,
			rotate: -5,
			ease:'none',
		}, '<',

	);
}

const scrollingText = function () {
	gsap.utils.toArray(".intro__md").forEach((scrollTextBox) => {
		const scrollText = scrollTextBox;

		const tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: ".intro__md",
				scrub: true,
				start: "top top",
				end: "bottom top",
				pin: true,
			},
		});

		tl2.fromTo(
			scrollText,
			{
				xPercent: '100',
				ease: "ease.out",
			},
			{
				xPercent: '-100',
				ease: "ease.out",
			},

		);
	});
}
const introText = function () {
	const tl3 = gsap.timeline({
		scrollTrigger: {
			trigger: ".intro__text",
			scrub: true,
			start: "top top",
			end: "bottom top",
			pin: true,
			//markers: true,
		},
	});


	document.querySelectorAll('.intro__text__inner').forEach((box) => {
		tl3.fromTo(
			box,
			{
				backgroundSize: '0% 100%',
				ease: "none",
			},
			{
				backgroundSize: '100% 100%',
				ease: "none",
			},

		);
	});
	tl3.fromTo(
		".intro__text",
		{
			autoAlpha: 1,
			ease: "none",
		},
		{
			autoAlpha: 0,
			ease: "none",

		},
		"+=3"
	);
}

const parallaxImg = function () {
	const items = gsap.utils.toArray(".js__parallax");

	items.forEach((item) => {
		gsap.fromTo(
			item.querySelector(".inner"),
			{
				yPercent: 0,
			},
			{
				yPercent: -10,
				ease: "none",
				scrollTrigger: {
					trigger: item,
					start: "top bottom",
					end: "bottom top",
					scrub: 1,
				},
			}
		);
	});
}
const aboutScroll = function () {
	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: ".about__tex1",
			scrub: true,
			start: "top bottom",
			end: "bottom top",
			scrub: true,
		},
	});

	tl2.to(
		document.querySelector('.about__tex1'),
		{
			rotate: 8,
			scale:.94,
			ease:'none',
		},

	);
}
const aboutScroll2 = function () {
	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: ".partner__tex1",
			scrub: true,
			start: "top bottom",
			end: "bottom top",
			scrub: true,
		},
	});

	tl2.to(
		document.querySelector('.partner__tex1'),
		{
			rotate: -8,
			scale:.94,
			ease:'none',
		},

	);
}

const animBlock = function () {
	gsap.utils.toArray(".js--anim__block").forEach((target) => {

		const items = target.querySelectorAll(".js--anim");

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: target,
				start: "top bottom-=30%",
			},
		});
		items.forEach(item => {
			if (item.classList.contains("js--pic") == true) {
				console.log(item)
				tl.to(
					item,
					{
						'--clip2': '0',
						duration: .4,
						ease: "expo.out",
					}, "<.1",

				);
				tl.to(
					item.querySelector(".js--pic__inner"),
					{
						width: 0,
						duration: .4,
						ease: "expo.out",
					}, "<.2",

				);
			} else if (item.classList.contains("js--title") == true) {
				tl.to(
					item,
					{
						'--clip': '0',
						duration: .4,
						ease: "expo.out",
					}, "<.1",

				);

				tl.to(
					item.children,
					{
						backgroundSize: '100% 100%',
						duration: .4,
						ease: "expo.out",
					}, "<.18",

				);
			}

		});
	});
}

const footerScroll = function () {
	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: ".footer",
			scrub: true,
			start: "top bottom",
			end: "bottom+=100% bottom",
			scrub: true,
		},
	});

	tl2.fromTo(
		document.querySelector('.footer'),
		{
			yPercent: -100,
		},
		{
			yPercent: 0,
			ease:'none',
		},

	);
}
window.addEventListener("pageshow", function () {
	const lenis = new Lenis({
		lerp: 0.5, // 慣性の強さ
		duration: 1.7, // スクロールアニメーションの時間
		easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
		smooth: true,
		smoothTouch: false,
	});

	function raf(time) {
		lenis.raf(time);
		ScrollTrigger.update();
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);

	lenis.scrollTo("html", 'duration:0');

	const btn = document.querySelector('.header_btn');
	const menu_target = document.querySelector('.gnav');
	const menu_overlay = document.querySelector('.gnav__overlay');




	let state = false;

	btn.addEventListener("click", function () {
		let scrollbarWidth = window.innerWidth - document.body.clientWidth;
		const vh = window.innerHeight;
		const windowHeight = document.documentElement.clientHeight;
		const header = document.querySelector('.header');
		const headerHeight = header.offsetHeight;

		if (state == false) {
			this.classList.add('active');
			//html.classList.add('fixed');
			menu_target.classList.add('active');
			menu_overlay.classList.add('active');
			document.querySelector('body').classList.add('fixed');
			btn.style.marginRight = scrollbarWidth + 'px';
			document.querySelector('body').style.marginRight = scrollbarWidth + 'px';
			state = true;

			lenis.stop();
		} else {
			this.classList.remove('active');
			//html.classList.remove('fixed');
			menu_overlay.classList.remove('active');
			menu_target.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			btn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			state = false;
			lenis.start();
		}
	});

	//アンカーリンク
	const anchorLinks = document.querySelectorAll('a[href^="#"]');
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
	const header = document.querySelector('.header');
	const anchorHash = location.hash;

	//ハッシュ値でのアンカーリンク
	anchorLinksArr.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			const targetId = link.hash;
			const targetElement = document.querySelector(targetId);
			const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
			const headerHeight = 0;
			//const totalScrollAmount = targetOffsetTop - headerHeight;
			const totalScrollAmount = targetOffsetTop - headerHeight;
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});

			btn.classList.remove('active');
			//html.classList.remove('fixed');
			menu_target.classList.remove('active');
			menu_overlay.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			btn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			state = false;
			lenis.start();
		});
	});

	if (anchorHash) {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		const targetElement = document.querySelector(anchorHash);
		const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
		const headerHeight = 0;
		const totalScrollAmount = targetOffsetTop - headerHeight;
		//const totalScrollAmount = targetOffsetTop - 40;

		var fn = function () {
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});
		};
		var tm = 500;
		setTimeout(fn, tm);
	}

	loadAnim();
	heroScroll();
	introText();
	scrollingText();
	aboutScroll();
	aboutScroll2();
	animBlock();
	parallaxImg();
	footerScroll();
	ScrollTrigger.refresh;

});
window.addEventListener("resize", function () {
	setScrollbarWidth();
});
